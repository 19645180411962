import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "../src/assets/main.css";
import ElementPlus from "element-plus";
import 'element-plus/dist/index.css'
const hls = require("videojs-contrib-hls");
const bool =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
const app = createApp(App);
app.provide("bool", bool);
app.config.globalProperties.$bool = bool;
app.use(router).use(ElementPlus).use(hls).mount("#app");
